
import { useRoute, useRouter } from 'vue-router';
import { showToast } from '@/hooks/useToast';
import { userState } from '@/store/user.store';
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import UITable from '@/components/UI/UITable.vue';
import UITableNav from '@/components/UI/UITableNav.vue';
import { calidadProductosState, especiesState, factorCortezaState, grupoProductosState } from '@/store/mantenedores.store';
import UISimpleInput from '@/components/UI/UISimpleInput.vue';
import UIModal from '@/components/UI/UIModal.vue';
import UIButton from '@/components/UI/UIButton.vue';
import ProductFilter from '@/components/common/ProductFilter.vue';

export default defineComponent({
  name: 'ProductList',
  components: {
    'ui-table': UITable,
    'ui-modal': UIModal,
    'ui-button': UIButton,
    'ui-table-nav': UITableNav,
    'ui-simple-input': UISimpleInput,
    'product-filter': ProductFilter
  },
  setup() {
    const userStoreState = computed(() => userState.value);
    const route = useRoute();
    const router = useRouter();
    const routeName = computed(() => (route.meta?.routeName ? route.meta?.routeName : 'no-name'));
    const calidadProductos = computed(() => calidadProductosState.value);
    const especies = computed(() => especiesState.value);
    const factorCorteza = computed(() => factorCortezaState.value);
    const grupoProducto = computed(() => grupoProductosState.value);

    const headers = [
      { label: 'Calidad', key: 'calidadText', format: '' },
      { label: 'Código', key: 'codigo', format: '' },
      { label: 'Código Adicional', key: 'codigoAdicional', format: '' },
      { label: 'SAP', key: 'codigoSAP', format: '' },
      { label: 'SISCOP', key: 'codigoSISCOP', format: '' },
      { label: 'Especie', key: 'especieText', format: '' },
      { label: 'Estado', key: 'estado', format: '' },
      { label: 'Factor Corteza', key: 'factorCortezaText', format: '' },
      { label: 'Grupo', key: 'grupoText', format: '' },
      { label: 'largo', key: 'largo', format: '' },
      { label: 'Largo Final', key: 'largoFinal', format: '' },
      { label: 'Largo Inicial', key: 'largoInicial', format: '' },
      { label: 'Largo Nominal', key: 'largoNominal', format: '' },
      { label: 'Largo Variable', key: 'largoVariable', format: '' }
    ];
    const items = ref<any[]>([]);
    const itemsCount = reactive<{
      total: number;
      actual: number;
      initial: number;
    }>({ total: 0, actual: 0, initial: 0 });
    const filteredItems = ref<any[]>([]);
    const filter = reactive<{ [k: string]: any }>({
      estado: '',
      especie: '',
      qty: 10,
      page: 1,
      order: 'DESC'
    });
    const isLoading = ref(false);

    const onfilter = async (f: {[k: string]: any}) => {
      f.especie && (filter.especie = f.especie);
      f.estado && (filter.estado = f.estado);
      await requestProducts();
      showToast('SUCCESS', 'Filtro Aplicado');
    };
    const onunfilter = () => {
      filter.estado = '';
      filter.especie = '';
      requestProducts();
    }

    const formatProductData = (el: any) => {
      return {
        ...el,
        calidadText: calidadProductos.value.find((calidad: any) => calidad._id === el.calidad)?.descripcion,
        especieText: especies.value.find((especie: any) => especie._id === el.especie)?.descripcion,
        factorCortezaText: factorCorteza.value.find((factor: any) => factor._id === el.factorCorteza)?.descripcion,
        grupoText: grupoProducto.value.find((grupo: any) => grupo._id === el.grupo)?.descripcion
      };
    };

    const requestProducts = async () => {
      try {
        isLoading.value = true;
        const request = await makeRequest<any>(METHODS.GET, `productos/all`, true, {}, { ...filter});
        if (request.ok) {
          const mapped = request.payload.items.map((el: any) => {
            return {
              id: el._id,
              data: formatProductData(el)
            };
          });
          items.value = [...mapped];
          filteredItems.value = [...mapped];
          itemsCount.actual = request.payload.items.length;
          itemsCount.initial = 1;
          itemsCount.total = request.payload.total;
        }
      } finally {
        isLoading.value = false;
      }
    };

    const sortProducts = () => {
      items.value = items.value.sort((a: any, b: any) => {
        if (a.data.codigo < b.data.codigo) {
          return -1;
        }
        if (a.data.codigo > b.data.codigo) {
          return 1;
        }
        return 0;
      });
    };

    const searchProduct = async (evt: string) => {
      console.log(evt);
      isLoading.value = true;
      if (!evt) return requestProducts();
      const request = await makeRequest<any>(METHODS.GET, `productos-by-regex?term=${evt}`, true, {}, {});
      if (request.ok) {
          const mapped = request.payload.items.map((el: any) => {
            return {
              id: el._id,
              data: formatProductData(el)
            };
          });
          items.value = [...mapped];
          filteredItems.value = [...mapped];
          itemsCount.actual = request.payload.items.length;
          itemsCount.initial = 1;
          itemsCount.total = request.payload.total;
          showToast('SUCCESS', 'Búsqueda  Aplicada');
        }
    };

    const updateProductStatus = async (i: number) => {
      const product = items.value[i].data;
      const estado = product.estado === 'HABILITADO' ? 'deshabilitado' : 'habilitado';
      const request = await makeRequest<any>(METHODS.PATCH, `producto/status/${product._id}/${estado}`, true);
      if (request.ok) {
        showToast('SUCCESS', 'Producto actualizado');
        items.value[i].data.estado = request.payload.estado;
        filteredItems.value = [...items.value];
      }
    };

    const editProduct = (i: number) => {
      router.push({ name: 'product.edit', params: { id: filteredItems.value[i].data._id } });
    };
    const modalRemovalState = ref(false);
    const selectedToRemoval = ref<any>(null);
    const isRemoving = ref(false);
    function onRemoveProductOpenModal(i: number) {
      selectedToRemoval.value = filteredItems.value[i];
      modalRemovalState.value = true;
    }
    const removeProduct = async () => {
      try {
        isRemoving.value = true;
        const request = await makeRequest<any>(METHODS.DELETE, `producto/${selectedToRemoval.value.data._id}`, true);
        if (request.ok) {
          showToast('SUCCESS', 'Producto eliminado');
          const i = filteredItems.value.findIndex((el: any) => el.data._id === selectedToRemoval.value.data._id);
          filteredItems.value.splice(i, 1);
          const j = items.value.findIndex((el: any) => el.data._id === selectedToRemoval.value.data._id);
          items.value.splice(j, 1);
        }
      } catch (error) {
        console.log(error);
      } finally {
        isRemoving.value = false;
        modalRemovalState.value = false;
      }
    };
    const actions = [
      {
        label: 'ESTADO',
        class: 'bg-yellow-600 text-white',
        cb: (i: number) => updateProductStatus(i)
      },
      {
        label: 'EDITAR',
        class: 'bg-blue-600 text-white',
        cb: (i: number) => editProduct(i)
      },
      {
        label: 'ELIMINAR',
        class: 'bg-red-600 text-white',
        cb: (i: number) => onRemoveProductOpenModal(i)
      }
    ];

    const onPageRequest = (p: number) => {
      filter.page = p;
      requestProducts();
    };
    const onPagedRequest = (q: number) => {
      filter.page = 1;
      filter.qty = q;
      requestProducts();
    };

    onMounted(async () => {
      requestProducts();
      sortProducts();
    });

    return {
      route,
      routeName,
      userStoreState,
      headers,
      filteredItems,
      filter,
      searchProduct,
      updateProductStatus,
      actions,
      selectedToRemoval,
      modalRemovalState,
      removeProduct,
      isRemoving,
      itemsCount,
      isLoading,
      onPageRequest,
      onPagedRequest,
      onfilter,
      onunfilter
    };
  }
});
